import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const seoQuery = graphql`
  {
    site {
      siteMetadata {
        siteAuthor: author
        siteDescription: description
        siteTitle: title
        siteTwitterImage: twitterImage
        siteOGImage: ogImage
        siteUrl: siteUrl
      }
    }
    enSocial: prismicSocial(lang: { eq: "en-gb" }) {
      data {
        siteOGImage: opengraph_image {
          url
        }
        siteTwitterImage: twitter_image {
          url
        }
      }
    }
    bgSocial: prismicSocial(lang: { eq: "bg" }) {
      data {
        siteOGImage: opengraph_image {
          url
        }
        siteTwitterImage: twitter_image {
          url
        }
      }
    }
  }
`
const SEO = ({ author, description, lang, image, meta, title }) => (
  <StaticQuery
    query={`${seoQuery}`}
    render={(data) => {
      const seo = {
        author: author || data.site.siteMetadata.siteAuthor,
        title: title || data.site.siteMetadatasiteTitle,
        description: description || data.site.siteMetadatasiteDescription,
      }
      let langTwitter = lang.slice(0, 2) + "Social"
      let langOG = lang.slice(0, 2) + "Social"
      let imageTwitter = image || data[langTwitter].data.siteTwitterImage.url
      let imageOG = image || data[langOG].data.siteOGImage.url
      return (
        <>
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={seo.title}
            titleTemplate={`%s | ${seo.title}`}
            meta={[
              {
                name: `test`,
                content: imageTwitter,
              },
              {
                name: `description`,
                content: seo.description,
              },
              {
                property: `og:title`,
                content: seo.title,
              },
              {
                property: `og:description`,
                content: seo.description,
              },
              {
                property: `og:image`,
                content: imageOG,
              },
              {
                property: `og:image:width`,
                content: `1200`,
              },
              {
                property: `og:image:height`,
                content: `630`,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: seo.author,
              },
              {
                name: `twitter:title`,
                content: seo.title,
              },
              {
                name: `twitter:image`,
                content: imageTwitter,
              },
              {
                name: `twitter:description`,
                content: seo.description,
              },
            ].concat(meta)}
          />
        </>
      )
    }}
  />
)

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
}

export default SEO
