import React, { PureComponent } from 'react'
import CheckLink from '../components/checkLink'

class Dropdown extends PureComponent {
  constructor() {
    super()
    this.state = {
      subNavExpanded: false,
    }
    this.toggleSubNav = this.toggleSubNav.bind(this);
  }
  container = React.createRef();
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId:false
      })
    } else {
      this.setState({
        subNavExpandedId:id
      })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = event => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        subNavExpandedId:false
      });
    }
  };

  render() {
    return (
      <li ref={this.container} className='mx-4 lg:mx-2'>
        <button className='w-full lg:w-auto dropdown px-1 py-3 flex flex-row items-center hover:text-blue-500 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50' aria-haspopup='true' aria-expanded={this.state.subNavExpandedId === this.props.children ? 'true':'false'} onClick={() => this.toggleSubNav(`${this.props.children}`)} onKeyDown={() => this.toggleSubNav(`${this.props.children}`)}>
          {this.props.children}
          <svg xmlns='http://www.w3.org/2000/svg' className='w-5 ml-auto' height='1em' width='1em'>
            <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='currentColor'/>
            <path d='M0 0h24v24H0z' fill='none'/>
          </svg>
        </button>
        <ul className={`${this.state.subNavExpandedId === this.props.children ? 'lg:grid lg:grid-cols-2 text-lg lg:p-8 lg:w-full lg:absolute lg:right-0 lg:rounded-xl lg:bg-white lg:shadow-lg block':'hidden'}`}>
          {this.props.items.map((sub_nav, index) => {
            return (
              <li key={`sub_nav-${index}`} className={`flex${index === 0 ? (' uppercase font-semibold border-b col-span-2 lg:mb-4 lg:pb-2'):('')}`}>
                <CheckLink className='flex-1 px-2 mx-2 py-2 lg:text-gray-900 hover:text-blue-500 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50' link={sub_nav.sub_nav_link}>
                  {sub_nav.sub_nav_link_label}
                  {sub_nav.sub_nav_intro && <p className='lowercase font-normal lg:text-gray-600'>{sub_nav.sub_nav_intro}</p>}
                </CheckLink>
              </li>
            )
          })}
        </ul>
      </li>
    )
  }
}

export default Dropdown
