import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import get from 'lodash/get';

const languageQuery = graphql`
  {
    engb: file(relativePath: { eq: "flags/unitedkingdom.png" }) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    bg: file(relativePath: { eq: "flags/bulgaria.png" }) {
      childImageSharp {
        fixed(height: 25) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const Flag = props => {
  var language = props.language
  var hideLabel = props.hideLabel

  return (
    <StaticQuery
      query={`${languageQuery}`}
      render={data => {
        var languageStrip = language.toLowerCase().replace(' ','').replace('-','')
        if (get(data[languageStrip], 'childImageSharp.fixed')) {
          var languageImage = data[languageStrip].childImageSharp.fixed
        }
        return (
          <>
           {languageImage ? (
              <span className='flex justify-center items-center font-medium'>
                <Img fixed={languageImage} className='lg:rounded-l-full overflow-hidden' alt={`${language} logo`} /><span className={`ml-2 mr-1 ${hideLabel && ('sr-only')}`}>
                  {language === 'en-gb' && 'English'}
                  {language === 'bg' && 'Български'}
                </span>
              </span>
            ):(
              <><span>No flag found for {language}</span></>
            )}
          </>
        )
      }}
    />
  )
}

export default Flag
