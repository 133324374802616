import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'

const CheckLink = (props) => {
  if (!props.link) return null
  return (
    <>
      {get(props.link, 'isBroken') !== true && (
        get(props.link, 'link_type') === 'Document' ? (
          <Link
            activeClassName={props.activeClassName}
            className={props.className}
            to={props.link.url || props.link.uid}>
              {props.children}
          </Link>
        ):(
          <a
            className={props.className}
            href={props.link.url}
            target={props.link.link_type === 'Media' ? ('_blank'):(props.link.target)}
            rel='noopener noreferrer'>
              {props.children}
          </a>
        )
      )}
    </>
  )
}
export default CheckLink
