import React from "react"
import { graphql, StaticQuery } from "gatsby"
import CheckLink from "../components/checkLink"
import Img from "gatsby-image"
import Social from "../components/social"

let newDate = new Date()
let year = newDate.getFullYear()

const footerQuery = graphql`
  {
    engb: file(relativePath: { eq: "flags/uk-rectangle.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    bg: file(relativePath: { eq: "flags/bg-rectangle.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export const Footer = (props) => {
  const footerData = props.navigation.data
  const socialData = props.social.data
  if (!footerData || !socialData) return null
  return (
    <StaticQuery
      query={`${footerQuery}`}
      render={(data) => (
        <footer
          role="contentinfo"
          className="bg-gray-900 text-gray-100 mt-auto"
        >
          <div className="max-w-screen-xl mx-auto px-4 pt-12 lg:pt-16">
            <svg
              className="mb-8"
              xmlns="http://www.w3.org/2000/svg"
              height="48px"
              width="212px"
              viewBox="0 0 1575.779 374"
            >
              <defs />
              <defs />
              <path
                fill="#fff"
                d="M57.174 325.584a73.822 73.822 0 01-29.638-14.493c-15.07-12.385-24.255-28.136-27.05-47.536a54.61 54.61 0 01-.431-7.832Q.046 166.343 0 76.963c0-2.328.5-3.1 2.988-3.087q27.914.17 55.83.015c2.4-.01 3.066.6 3.063 3.04q-.105 81.9-.047 163.82c0 11.638 6.832 20.872 17.53 23.877a26.6 26.6 0 007.27.778q39.84.01 79.682-.008c15.57-.027 25.534-10.092 25.534-25.635q0-81.254-.055-162.5c0-2.578.525-3.4 3.28-3.38q27.914.22 55.83.048c2.32-.01 2.914.6 2.9 2.92q-.1 88.725-.065 177.45c-.027 32.606-22.9 62.063-54.463 70.446a103.87 103.87 0 01-8.949 1.942c-.847.477-1.768.144-2.65.243-1.912.14-3.853-.3-5.746.27H72.37c-1.082-.547-2.24-.14-3.357-.27h-3.976a46.02 46.02 0 01-7.863-1.34zm273.663 1.61c-.525-1.06-1.614-.9-2.5-1.108-23.2-5.546-40.068-19.164-50.68-40.38a68.623 68.623 0 01-7.156-31.113l.153-107.98c.513-30.906 15.748-53.017 43.356-66.6 9.637-4.744 20-6.6 30.717-6.588q87.932.02 175.864-.052c2.47 0 2.816.878 2.808 3.026q-.117 27.912 0 55.825c.015 2.546-.7 3.166-3.2 3.16q-81.117-.108-162.234-.077c-8.486-.007-15.526 2.822-20.785 9.626a21.19 21.19 0 00-4.716 13.075l.016 84.656c.06 12.378 10.25 22.57 22.63 23.152 1.4.065 2.8.066 4.193.066h160.662c3.377 0 3.38 0 3.375 3.4q-.045 28.96-.1 57.918zm165.445-76.4c-23.415 0-46.835-.264-70.245.115-11.15.18-16.97-8.55-16.834-16.8l.13-68.4c0-9.77 6.18-15.95 16.122-15.957q50.325-.036 100.65-.01h40.9c10.567.008 16.58 6.03 16.578 16.563v68.673c0 9.526-6.165 15.784-15.734 15.8q-35.78.055-71.557.014zm-317.77-144.6v72.074a14.71 14.71 0 01-13.532 14.985 29.12 29.12 0 01-2.878.137q-33.8.012-67.618.02c-5.9.01-10.77-1.88-14.125-6.965a13.48 13.48 0 01-2.336-7.632q.04-72.6.03-145.2c-.027-7.734 6.2-14.042 13.923-14.128q36.037-.1 72.074-.02c8.416.018 14.457 6.233 14.46 14.653q.01 36.038.002 72.075z"
              />
              <text
                fill="#fff"
                fontFamily="Source Sans Pro"
                fontSize="298"
                fontWeight="600"
              >
                <tspan x="628.779" y="293">
                  Unicard
                </tspan>
              </text>
            </svg>
            <div className="md:grid md:grid-cols-5 md:gap-8">
              <div className="col-span-2 mb-6">
                <div className="pb-2 mb-3 uppercase font-bold text-xl text-gray-300">
                  {footerData.title}
                </div>
                <div className="grid grid-cols-2">
                  {footerData.body1.map((companyAddress, index) => {
                    return (
                      <div
                        className="inline leading-snug text-white"
                        key={`companyAddress-${index}`}
                      >
                        <div>
                          {index === 0 ? (
                            <Img
                              className="h-4"
                              fixed={
                                data[props.navigation.lang.replace("-", "")]
                                  .childImageSharp.fixed
                              }
                              alt=""
                            />
                          ) : (
                            <Img
                              className="h-4"
                              fixed={
                                data[
                                  props.navigation.alternate_languages[0].lang.replace(
                                    "-",
                                    ""
                                  )
                                ].childImageSharp.fixed
                              }
                              alt=""
                            />
                          )}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: companyAddress.primary.company_address.html,
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
              {footerData.body.map((footer, index) => {
                return (
                  <div key={`footerList-${index}`} className="col-span-1 mb-6">
                    {footer.primary.title && (
                      <div
                        className="pb-2 mb-3 uppercase font-bold text-xl text-gray-300"
                        dangerouslySetInnerHTML={{
                          __html: footer.primary.title,
                        }}
                      />
                    )}
                    <ul>
                      {footer.items.map((footerItem, index) => {
                        return (
                          <li key={`footerItem-${index}`}>
                            <CheckLink
                              className="mb-3 pb-2 md:pb-0 block text-white hover:text-blue-100 hover:underline focus:outline-none focus:ring-2 ring-offset-2 focus:ring-blue-50 focus:ring-opacity-50"
                              link={footerItem.link}
                            >
                              {footerItem.link_label}
                            </CheckLink>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
            <div className="flex flex-wrap justify-center gap-4 md:justify-between mt-5 border-t border-gray-800 py-5">
              <div className="flex-grow flex justify-center md:justify-start text-center items-center gap-4">
                <Social networks={socialData} />
              </div>
              <div className="flex-grow flex justify-center md:justify-end text-center items-center gap-4">
                {footerData.certification.map((certification, index) => {
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={certification.link.url}
                      key={`certification-${index}`}
                      className="focus:outline-none focus:ring-2 ring-offset-2 focus:ring-blue-50 focus:ring-opacity-50"
                    >
                      <img src={certification.logo.url} alt="" />
                    </a>
                  )
                })}
              </div>
            </div>
            <div className="border-t border-gray-800 py-5">
              <div className="text-base font-light text-gray-100 text-center">
                &copy; {year} {footerData.company_display_name}
              </div>
            </div>
          </div>
        </footer>
      )}
    />
  )
}

export default Footer
