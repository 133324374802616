import React, { useState, useEffect } from "react"

function Alert(props) {
  function useStickyState(defaultValue, key) {
    const [value, setValue] = useState(() => {
      const stickyValue =
        typeof window === "undefined" ? false : window.localStorage.getItem(key)
      if (stickyValue === false) return null
      if (stickyValue !== null) return JSON.parse(stickyValue)
      return defaultValue
    })
    useEffect(() => {
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(value))
      }
    }, [key, value])
    return [value, setValue]
  }

  const [display, setAlert] = useStickyState("flip", "hide")

  return (
    <div
      className={`bg-yellow-50 p-4 mb-3 shadow-sm component-alert ${
        display === "hidden" || !display ? "hidden" : ""
      }`}
    >
      <div className="max-w-screen-xl w-full mx-auto px-4 md:px-8 flex flex-row items-center">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-yellow-400"
            xmlns="http://www.w3.org/2000/svg"
            height="1.25em"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <div className="font-medium text-yellow-700">
            <div dangerouslySetInnerHTML={{ __html: props.text.html }} />
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              className="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
              onClick={() => setAlert("hidden")}
            >
              <span className="sr-only">Dismiss</span>
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Alert
