import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import CookieConsent from 'react-cookie-consent';
import Alert from './alert'
import Header from './header'
import Footer from './footer'


const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Layout = props => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={data => (
        <div className='antialiased layout text-gray-800 text-lg leading-6 flex flex-col min-h-screen'>
          {props.navigation.data.alert && (
            <Alert text={props.navigation.data.alert} />
          )}
          <Header siteTitle={data.site.siteMetadata.title} altLang={props.altLang} navigation={props.navigation} />
          <main role='main' id='main'>{props.children}</main>
          <Footer navigation={props.navigation} social={props.social} />
          <CookieConsent
            location='bottom'
            buttonText={props.navigation.data.cookie_accept || 'Accept'}
            declineButtonText={props.navigation.data.cookie_decline || 'Decline'}
            disableStyles
            containerClasses='cookie z-30 fixed inset-x-0 bg-white px-4 py-2 flex flex-col sm:flex-row items-center justify-center border'
            buttonClasses='ml-2 button bg-blue-500 text-white rounded font-semibold border border-white'
            declineButtonClasses='ml-2 px-6 border-transparent'
            contentClasses='py-2 flex-1'
            enableDeclineButton
            cookieName='gatsby-gdpr-google-analytics'>
            <div dangerouslySetInnerHTML={{__html:props.navigation.data.cookie_text.html}} />
          </CookieConsent>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  altLang: PropTypes.array,
  navigation: PropTypes.object,
  social: PropTypes.object,
}

export default Layout
