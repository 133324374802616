import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import Flag from './flag'

class LanguageSelector extends PureComponent {
  constructor() {
    super()
    this.state = {
      subNavExpanded: false,
    }
    this.toggleSubNav = this.toggleSubNav.bind(this);
  }
  container = React.createRef();
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId:false
      })
    } else {
      this.setState({
        subNavExpandedId:id
      })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClickOutside = event => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        subNavExpandedId:false
      });
    }
  };

  render() {
    if(!this.props.altLangs) return null
    return (
      <li ref={this.container} className='mx-4 py-2 relative lg:text-white'>
        {this.props.altLangs.length < 1 ? (
          <div className='w-full lg:w-auto lg:bg-blue-600 dropdown flex flex-row focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50 items-center px-1 lg:px-2 py-3 lg:py-1 lg:rounded-full transform transition duration-150 ease-in-out'>
            <Flag language={this.props.lang} />
          </div>
        ):(
          <>
            <button type='button' className='w-full lg:w-auto lg:bg-blue-600 lg:hover:bg-blue-700 dropdown flex flex-row focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50 hover:text-blue-500 lg:hover:text-blue-100 items-center px-1 lg:px-2 py-1 lg:rounded-full transform transition duration-150 ease-in-out'  aria-haspopup='true' aria-expanded={this.state.subNavExpanded} onClick={() => this.toggleSubNav(this.props.lang)} onKeyDown={() => this.toggleSubNav(this.props.lang)}>
              <Flag language={this.props.lang} />
              <svg xmlns='http://www.w3.org/2000/svg' className='w-5 ml-auto' height='1em' width='1em'>
                <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='currentColor'/>
                <path d='M0 0h24v24H0z' fill='none'/>
              </svg>
            </button>
            <ul className={`${this.state.subNavExpandedId === this.props.lang ? 'block lg:absolute lg:overflow-hidden lg:left-0': 'hidden'}`}>
              {this.props.altLangs.map((altLang, index) => {
                return (
                  <li className='m-2 md:mx-0' key={`lang-${index}`}>
                    <Link className='block px-4 lg:px-2 py-1 lg:bg-blue-600 lg:hover:bg-blue-700 rounded-full lg:hover:text-blue-100 mt-1 transform transition duration-150 ease-in-out' to={altLang.url}>
                      <Flag language={altLang.lang} />
                    </Link>
                  </li>
                )
              })}
            </ul>
          </>
        )}
      </li>
    )
  }
}

export default LanguageSelector
